<template>
    <div class="bind-email">
        <!-- 綁定郵箱找回 -->
        <van-nav-bar
            :title="$t('LAN.bind_email')"
            :left-text="$t('LAN.back')"
            left-arrow
            @click-left="goBack"
        />

        <div class="content">
            <div class="email-tips">
                <p>{{$t('LAN.for_the_account')  }} {{ username }} {{$t('LAN.for_the_account_1')  }}</p>
            </div>

            <van-form v-if="type == 'old'" @submit="type = 'new'">
                <van-field
                    v-model="oldEmail"
                    :label="$t('LAN.bind_email')" 
                    :placeholder="$t('LAN.input_old_email')" 
                    clearable
                    center
                    left-icon="envelop-o"
                    :rules="[{ validator:validatorEmail, required: true, message: $t('LAN.input_validator_email') }]"
                />
                <van-field
                    v-model="oldVerify"
                    label="驗證碼"
                    center
                    left-icon="lock"
                    placeholder="請輸入舊郵箱驗證碼"
                    :rules="[{ required: true, message: '請輸入舊郵箱驗證碼' }]">
                    <template #button>
                        <van-button size="small" type="primary" color="#6e87ea" @click="sendOldVerify" :disabled="oldVerifyDisabled"> {{ oldVerifyBtnText }} </van-button>
                    </template>
                </van-field>
                <div style="margin: 16px;">
                    <van-button round block color="#6e87ea" native-type="submit">下一步</van-button>
                </div>
            </van-form>

            <van-form v-else @submit="handleBind">
                <van-field
                    v-model="email"
                    :label="$t('LAN.bind_email')" 
                    :placeholder="$t('LAN.input_old_email')" 
                    clearable
                    center
                    left-icon="envelop-o"
                    :rules="[{ validator:validatorEmail, required: true, message: $t('LAN.input_validator_email') }]"
                />
                <van-field
                    v-model="verify"
                    :label="$t('LAN.verify_code')" 
                    center
                    left-icon="lock"
                    :placeholder="$t('LAN.input_new_verify_code')" 
                    :rules="[{ required: true, message: $t('LAN.input_new_verify_code') }]">  
                    <template #button>
                        <van-button size="small" type="primary" color="#6e87ea" @click="sendVerify" :disabled="verifyDisabled">{{ verifyBtnText }}</van-button>
                    </template>
                </van-field>
                <div style="margin: 16px;">
                    <van-button round block color="#6e87ea" native-type="submit">{{ $t('LAN.bind_now')}}</van-button>
                </div>
            </van-form>

            <div class="email-tips">
                <p>*{{ $t('LAN.tips') }}</p>
                <p>{{ $t('LAN.email_tips')}}</p>
            </div>
        </div>
    </div>
</template>

<script>
import { reactive, onMounted, toRefs } from 'vue'
import { useStore } from 'vuex'
import { useRouter} from 'vue-router'
import { Toast } from 'vant'
import { validateEmail } from '@/utils/validate';
import { requestEmailVerifycode, requestBindEmail } from '@/service/user'
import { useI18n } from 'vue-i18n'
export default {
    setup() {
        const router = useRouter()
        const store = useStore()
        const { t } = useI18n()
        const state = reactive({
            type: 'old',
            username: '',
            email: '',
            verify: '',
            verifyTime:0,
            verifyDisabled: false,
            verifyBtnText:  t('LAN.send_verify_code'),

            oldEmail: '',
            oldVerify: '',
            oldVerifyTime:0,
            oldVerifyDisabled:false,
            oldVerifyBtnText: t('LAN.send_verify_code')
        })
        
        onMounted(() => {
            init()
        });
        
        const init = async () => {
            state.username = store.getters.username 

            state.oldEmail = store.getters.email || ''
            if (state.oldEmail == "") {
                state.type = "new";
            }
        }

        const sendOldVerify = async () => {
            if (state.oldEmail == '' || !validatorEmail(state.oldEmail)) {
                Toast(t('LAN.input_validator_email'))
                return;
            }
            Toast.loading(t('LAN.sending'));
            await requestEmailVerifycode({email:state.oldEmail});
            state.oldVerifyTime = 30
            onOldVerifyTimer()
            Toast(t('LAN.send_success'))
        }

        const onOldVerifyTimer = () => {
            if (state.oldVerifyTime > 0) {
                state.oldVerifyDisabled = true;
                state.oldVerifyTime --;
                state.oldVerifyBtnText =   t('LAN.resend') + `(${state.oldVerifyTime})`;
                setTimeout(onOldVerifyTimer, 1000);
            } else{
                state.oldVerifyTime = 0;
                state.oldVerifyBtnText = t('LAN.send_verify_code');
                state.oldVerifyDisabled = false;
            }
        }

        const sendVerify = async () => {
            if (state.email == '' || !validatorEmail(state.email)) {
                Toast(t('LAN.input_validator_email'))
                return;
            }
            Toast.loading(t('LAN.sending'));
            await requestEmailVerifycode({email:state.email});
            state.verifyTime = 30
            onVerifyTimer()
            Toast(t('LAN.send_success'))
        }

        const onVerifyTimer = () => {
            if (state.verifyTime > 0) {
                state.verifyDisabled = true;
                state.verifyTime --;
                state.verifyBtnText = t('LAN.resend') + `(${state.verifyTime})`;
                setTimeout(onVerifyTimer, 1000);
            } else{
                state.verifyTime = 0;
                state.verifyBtnText = t('LAN.send_verify_code');
                state.verifyDisabled = false;
            }
        }

        const handleBind = async () => {
            Toast.loading(t('LAN.sending'));
            await requestBindEmail({
                oldVerifyCode:state.verify,
                email:state.email,
                verify:state.verify
            });
            Toast(t('LAN.binding_successful'))
            goBack()
        }

        const validatorEmail = (email) => {
            return validateEmail(email)
        }

        const goBack = () => {
            router.go(-1)
        }

        return {
            ...toRefs(state),
            sendOldVerify,
            sendVerify,
            validatorEmail,
            handleBind,
            goBack
        }
    },
    components: {
    }
}
</script>

<style lang="less" scoped>
.bind-email {
    height: calc(var(--vh, 1vh) * 100);
    .content {
        margin-top: 10px;
    }
}
.email-tips {
    margin: 20px;
    text-align: left;
    color: #8c8c8c;
    font-size: 14px;
}
</style>